import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { VirtualWorldList } from 'javascript/features/virtualWorld/components/VirtualWorldList';
import { OfficeTitle } from 'javascript/features/realWorld/components/OfficeTitle';
import { FreeSpaceTemplate } from 'javascript/features/freeSpace/components';
import { VirtualWorld, UserAvatar } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  userImage: string;
  virtualWorldList: VirtualWorld[];
  plan: number;
  role: 'member' | 'master' | 'cast' | undefined;
  avatar?: UserAvatar;
  image?: string;
  isHiddenSubRoom?: boolean;
  isVideoOnline?: boolean;
  index?: number;
}

export const VirtualWorldTemplate: React.FC<Props> = ({
  avatar,
  userImage,
  virtualWorldList,
  plan,
  role,
  image,
  isHiddenSubRoom,
  isVideoOnline,
  index,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <OfficeTitle
          img='virtual-world.png'
          plan={plan}
          role={role}
          theme={image}
          isVirtual
        >
          {t('GenelalRoomVirtual', {})}
        </OfficeTitle>
      )}
      <StyledVirtualWorldWrapper worldTheme={image}>
        <VirtualWorldList
          virtualWorldList={virtualWorldList}
          image={image}
          isHiddenSubRoom={isHiddenSubRoom}
          isVideoOnline={isVideoOnline}
          index={index}
          isCast={role == 'cast'}
        />
        {!isMobile && (
          <FreeSpaceTemplate avatar={avatar} userImage={userImage} isCast={role == 'cast'} />
        )}
      </StyledVirtualWorldWrapper>
    </>
  );
};

const StyledVirtualWorldWrapper = styled.div<{ worldTheme?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 12px 0 8px;
  height: calc(100% - 16px);
  color: #000;
  background-image: ${({ worldTheme }) =>
    worldTheme
      ? // @ts-ignore
      `url(${RailsAssetPath(`worldTheme/${worldTheme}.png`)})`
      : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
