// 毎月追加されるアバターパーツのうち、どこからが新しく追加されたパーツかを判断する数値(product_number)を管理する定数

// 目
export const newEyeIndex = 216;
// 口
export const newMouthIndex = 195;
// 服
export const newClothIndex = 236;
// 髪
export const newHairIndex = 201;
// 帽子
export const newHatIndex = 212;
