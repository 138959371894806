import { FixedWidthAndPosition, FixedHeightAndPosition } from 'types';

/*
アバターパーツ着せ替えページの
着せ替え中のアバター表示エリアに表示されるアバターパーツの画像の大きさと位置を管理する定数
*/

// 目
export const eyeAppearanceInDisplayArea: FixedWidthAndPosition[] = [
  { width: 60, top: 108, left: 204 },
  { width: 62, top: 108, left: 204 },
  { width: 60, top: 108, left: 204 },
  { width: 60, top: 106, left: 204 },
  { width: 60, top: 110, left: 204 },
  { width: 62, top: 110, left: 204 },
  { width: 54, top: 108, left: 206 },
  { width: 62, top: 108, left: 204 },
  { width: 60, top: 108, left: 206 },
  { width: 64, top: 108, left: 204 },

  { width: 60, top: 111, left: 204 },
  { width: 60, top: 110, left: 204 },
  { width: 60, top: 108, left: 204 },
  { width: 60, top: 107, left: 204 },
  { width: 60, top: 107, left: 204 },
  { width: 60, top: 107, left: 204 },
  { width: 54, top: 108, left: 206 },
  { width: 54, top: 108, left: 206 },
  { width: 54, top: 106, left: 204 },
  { width: 54, top: 104, left: 204 },

  { width: 60, top: 108, left: 204 },
  { width: 60, top: 108, left: 204 },
  { width: 52, top: 108, left: 204 },
  { width: 56, top: 107, left: 202 },
  { width: 60, top: 110, left: 202 },
  { width: 60, top: 108, left: 202 },
  { width: 60, top: 110, left: 202 },
  { width: 60, top: 108, left: 202 },
  { width: 60, top: 114, left: 204 },
  { width: 60, top: 111, left: 204 },

  { width: 60, top: 108, left: 204 },
  { width: 60, top: 114, left: 204 },
  // 以下有料プロダクト
  { width: 52, top: 108, left: 204 },
  { width: 94, top: 98, left: 197 },
  { width: 96, top: 101, left: 196 },
  { width: 48, top: 114, left: 208 },
  { width: 52, top: 116, left: 208 },
  { width: 40, top: 114, left: 210 },
  { width: 60, top: 108, left: 203 },
  { width: 76, top: 97, left: 198 },

  { width: 86, top: 97, left: 198 },
  { width: 92, top: 100, left: 198 },
  { width: 60, top: 110, left: 204 },
  { width: 60, top: 110, left: 204 },
  { width: 64, top: 112, left: 204 },
  { width: 58, top: 110, left: 204 },
  { width: 49, top: 100, left: 206 },
  { width: 53, top: 110, left: 204 },
  { width: 56, top: 112, left: 204 },
  { width: 52, top: 114, left: 204 },

  { width: 44, top: 107, left: 206 },
  { width: 52, top: 114, left: 204 },
  { width: 52, top: 110, left: 204 },
  { width: 54, top: 112, left: 204 },
  { width: 88, top: 110, left: 200 },
  { width: 50, top: 98, left: 205 },
  { width: 40, top: 98, left: 209 },
  { width: 40, top: 114, left: 209 },
  { width: 48, top: 110, left: 208 },
  { width: 52, top: 115, left: 206 },

  { width: 60, top: 108, left: 201 },
  { width: 60, top: 113, left: 202 },
  { width: 61, top: 111, left: 202 },
  { width: 58, top: 109, left: 204 },
  { width: 60, top: 109, left: 196 },
  { width: 56, top: 112, left: 203 },
  { width: 40, top: 112, left: 208 },
  { width: 54, top: 114, left: 204 },
  { width: 54, top: 110, left: 204 },
  { width: 56, top: 114, left: 204 },

  { width: 56, top: 118, left: 204 },
  { width: 58, top: 108, left: 202 },
  { width: 56, top: 114, left: 202 },
  { width: 54, top: 112, left: 204 },
  { width: 54, top: 108, left: 204 },
  { width: 50, top: 110, left: 206 },
  { width: 50, top: 110, left: 206 },
  { width: 56, top: 112, left: 206 },
  { width: 56, top: 114, left: 206 },
  { width: 50, top: 110, left: 206 },

  { width: 60, top: 107, left: 210 },
  { width: 50, top: 107, left: 206 },
  { width: 50, top: 106, left: 206 },
  { width: 50, top: 102, left: 211 },
  { width: 42, top: 102, left: 214 },
  { width: 60, top: 108, left: 207 },
  { width: 59, top: 108, left: 208 },
  { width: 59, top: 108, left: 208 },
  { width: 59, top: 108, left: 208 },
  { width: 62, top: 109, left: 207 },

  { width: 62, top: 109, left: 207 },
  { width: 62, top: 109, left: 207 },
  { width: 62, top: 109, left: 207 },
  { width: 62, top: 105, left: 207 },
  { width: 62, top: 109, left: 207 },

  { width: 62, top: 109, left: 207 },
  { width: 62, top: 109, left: 207 },
  { width: 62, top: 109, left: 207 },
  { width: 62, top: 105, left: 207 },
  { width: 54, top: 109, left: 207 },

  { width: 62, top: 109, left: 207 }, // 101
  { width: 62, top: 109, left: 207 },
  { width: 55, top: 109, left: 212 },
  { width: 85, top: 101, left: 201 },
  { width: 62, top: 109, left: 207 },

  { width: 89, top: 109, left: 204 }, // 目 106
  { width: 66, top: 109, left: 204 },
  { width: 66, top: 109, left: 204 },
  { width: 66, top: 105, left: 204 },
  { width: 66, top: 109, left: 204 },
  
  { width: 62, top: 109, left: 207 }, // 目 111
  { width: 62, top: 109, left: 207 },
  { width: 55, top: 109, left: 209 },
  { width: 62, top: 109, left: 202 },
  { width: 62, top: 109, left: 207 },

  { width: 62, top: 109, left: 207 }, // 目 116
  { width: 100, top: 63, left: 199 },
  { width: 55, top: 109, left: 209 },
  { width: 62, top: 109, left: 207 },
  { width: 62, top: 103, left: 207 },

  { width: 62, top: 104, left: 207 }, // 目 121
  { width: 62, top: 97,  left: 209 },
  { width: 63, top: 100, left: 209 },
  { width: 62, top: 104, left: 207 },
  { width: 62, top: 103, left: 207 },

  { width: 62, top: 114, left: 207 }, // 目 126
  { width: 62, top: 109, left: 209 },
  { width: 63, top: 111, left: 209 },
  { width: 72, top: 110, left: 204 },
  { width: 62, top: 103, left: 207 },

  { width: 96, top: 83, left: 188 }, // 目 131
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 79, top: 98, left: 195 }, // 目 136
  { width: 72, top: 106, left: 203 },
  { width: 84, top: 107, left: 195 },
  { width: 94, top: 105, left: 192 },
  { width: 87, top: 104, left: 195 },

  { width: 76, top: 98, left: 198 }, // 目 141
  { width: 69, top: 109, left: 203 },
  { width: 90, top: 111, left: 195 },
  { width: 67, top: 108, left: 204 },
  { width: 72, top: 103, left: 202 },

  { width: 72, top: 109, left: 202 }, // 目 146
  { width: 65, top: 118, left: 203 },
  { width: 70, top: 110, left: 202 },
  { width: 99, top: 111, left: 189 },
  { width: 67, top: 117, left: 206 },

  { width: 96, top: 108, left: 193 }, // 目 151
  { width: 74, top: 110, left: 202 },
  { width: 101, top: 99, left: 194 },
  { width: 69, top: 114, left: 206 },
  { width: 81, top: 109, left: 201 },

  { width: 101, top: 103, left: 199 }, // 目 156
  { width: 66, top: 114, left: 204 },
  { width: 69, top: 108, left: 205 },
  { width: 79, top: 117, left: 200 },
  { width: 77, top: 112, left: 201 },

  { width: 74, top: 105, left: 202 }, // 目 161
  { width: 66, top: 106, left: 207 },
  { width: 71, top: 107, left: 204 },
  { width: 74, top: 90, left: 202 },
  { width: 93, top: 105, left: 197 },

  { width: 79, top: 111, left: 200 }, // 目 166
  { width: 72, top: 113, left: 199 },
  { width: 72, top: 110, left: 200 },
  { width: 95, top: 106, left: 192 },
  { width: 81, top: 112, left: 201 },

  { width: 70, top: 110, left: 204 }, // 目 171
  { width: 79, top: 110, left: 200 },
  { width: 85, top: 110, left: 198 },
  { width: 84, top: 111, left: 197 },
  { width: 85, top: 103, left: 196 },

  { width: 75, top: 110, left: 202 }, // 目 176
  { width: 94, top: 105, left: 197 },
  { width: 77, top: 101, left: 197 },
  { width: 76, top: 108, left: 201 },
  { width: 83, top: 96, left: 199 },

  { width: 86, top: 97, left: 192 }, // 目 181
  { width: 73, top: 112, left: 203 },
  { width: 72, top: 117, left: 202 },
  { width: 92, top: 105, left: 195 },
  { width: 77, top: 113, left: 202 },

  { width: 80, top: 111, left: 199 }, // 目 186
  { width: 97, top: 101, left: 194 },
  { width: 84, top: 114, left: 198 },
  { width: 73, top: 108, left: 202 },
  { width: 72, top: 95, left: 202 },

  { width: 50, top: 111, left: 213 }, // 目 191
  { width: 95, top: 107, left: 200 },
  { width: 74, top: 107, left: 199 },
  { width: 73, top: 99, left: 202 },
  { width: 92, top: 105, left: 195 },

  { width: 104, top: 102, left: 195 }, // 目 196
  { width: 76, top: 104, left: 202 },
  { width: 76, top: 106, left: 201 },
  { width: 83, top: 99, left: 198 },
  { width: 68, top: 103, left: 204 },

  { width: 76, top: 108, left: 201 }, // 目 201
  { width: 95, top: 104, left: 198 },
  { width: 71, top: 100, left: 203 },
  { width: 77, top: 116, left: 201 },
  { width: 75, top: 107, left: 202 },

  { width: 83, top: 111, left: 201 }, // 目 206
  { width: 95, top: 107, left: 198 },
  { width: 41, top: 115, left: 217 },
  { width: 86, top: 105, left: 199 },
  { width: 92, top: 110, left: 193 },

  { width: 74, top: 111, left: 202 }, // 目 211
  { width: 82, top: 99, left: 198 },
  { width: 71, top: 112, left: 205 },
  { width: 74, top: 112, left: 202 },
  { width: 86, top: 95, left: 197 },

  { width: 68, top: 111, left: 205 }, // 目 216
  { width: 72, top: 111, left: 203 },
  { width: 69, top: 116, left: 205 },
  { width: 68, top: 112, left: 203 },
  { width: 102, top: 100, left: 196 },
];

// 口
export const mouthAppearanceInDisplayArea: FixedWidthAndPosition[] = [
  { width: 24, top: 138, left: 218 },
  { width: 24, top: 138, left: 218 },
  { width: 20, top: 136, left: 220 },
  { width: 24, top: 136, left: 218 },
  { width: 24, top: 136, left: 218 },
  { width: 12, top: 132, left: 222 },
  { width: 18, top: 132, left: 222 },
  { width: 18, top: 138, left: 222 },
  { width: 12, top: 132, left: 222 },
  { width: 26, top: 138, left: 220 },

  // 以下有料プロダクト
  { width: 26, top: 138, left: 220 },
  { width: 30, top: 126, left: 216 },
  { width: 88, top: 117, left: 198 },
  { width: 16, top: 136, left: 220 },
  { width: 44, top: 140, left: 194 },
  { width: 88, top: 120, left: 198 },
  { width: 88, top: 120, left: 198 },
  { width: 24, top: 138, left: 214 },
  { width: 44, top: 124, left: 211 },
  { width: 94, top: 116, left: 198 },

  { width: 56, top: 118, left: 208 },
  { width: 40, top: 132, left: 212 },
  { width: 40, top: 132, left: 212 },
  { width: 24, top: 134, left: 216 },
  { width: 26, top: 136, left: 216 },
  { width: 26, top: 136, left: 216 },
  { width: 22, top: 134, left: 218 },
  { width: 18, top: 132, left: 222 },
  { width: 18, top: 132, left: 222 },
  { width: 26, top: 136, left: 218 },

  { width: 26, top: 134, left: 218 },
  { width: 30, top: 136, left: 216 },
  { width: 32, top: 134, left: 216 },
  { width: 36, top: 134, left: 214 },
  { width: 30, top: 136, left: 216 },
  { width: 32, top: 134, left: 216 },
  { width: 8, top: 134, left: 224 },
  { width: 20, top: 132, left: 218 },
  { width: 22, top: 134, left: 218 },
  { width: 24, top: 136, left: 218 },

  { width: 26, top: 136, left: 217 },
  { width: 20, top: 132, left: 220 },
  { width: 22, top: 136, left: 220 },
  { width: 36, top: 138, left: 214 },
  { width: 20, top: 133, left: 219 },
  { width: 28, top: 137, left: 219 },
  { width: 30, top: 133, left: 218 },
  { width: 26, top: 133, left: 218 },
  { width: 28, top: 140, left: 218 },
  { width: 40, top: 132, left: 218 },

  { width: 40, top: 130, left: 202 },
  { width: 28, top: 138, left: 217 },
  { width: 18, top: 138, left: 220 },
  { width: 12, top: 138, left: 222 },
  { width: 22, top: 124, left: 218 },
  { width: 92, top: 119, left: 198 },
  { width: 28, top: 128, left: 208 },
  { width: 18, top: 138, left: 222 },
  { width: 22, top: 138, left: 220 },
  { width: 40, top: 132, left: 225 },

  { width: 30, top: 137, left: 219 },
  { width: 10, top: 132, left: 226 },
  { width: 39, top: 141, left: 215 },
  { width: 25, top: 135, left: 216 },
  { width: 35, top: 116, left: 217 },
  { width: 30, top: 136, left: 222 },
  { width: 26, top: 127, left: 215 },
  { width: 26, top: 135, left: 220 },
  { width: 29, top: 120, left: 221 },

  { width: 29, top: 136, left: 221 },
  { width: 29, top: 136, left: 221 },
  { width: 29, top: 136, left: 221 },
  { width: 17, top: 132, left: 228 },
  { width: 15, top: 132, left: 228 },

  { width: 29, top: 136, left: 221 },
  { width: 29, top: 136, left: 221 },
  { width: 29, top: 136, left: 221 },
  { width: 42, top: 130, left: 227 },
  { width: 28, top: 130, left: 219 },

  { width: 18, top: 136, left: 230 }, // 80
  { width: 18, top: 144, left: 230 },
  { width: 18, top: 144, left: 230 },
  { width: 18, top: 131, left: 230 },
  { width: 18, top: 135, left: 230 },

  { width: 27, top: 136, left: 225 }, // 口 85
  { width: 27, top: 136, left: 225 },
  { width: 21, top: 136, left: 225 },
  { width: 16, top: 133, left: 230 },
  { width: 21, top: 136, left: 225 },

  { width: 117, top: 107, left: 156 }, // 口 90
  { width: 89, top: 118, left: 199 },
  { width: 89, top: 118, left: 199 },
  { width: 18, top: 131, left: 230 },
  { width: 18, top: 135, left: 230 },
  { width: 18, top: 135, left: 230 },

  { width: 18, top: 136, left: 230 }, // 口 96
  { width: 42, top: 134, left: 222 },
  { width: 25, top: 135, left: 230 },
  { width: 30, top: 135, left: 224 },
  { width: 18, top: 135, left: 230 },

  { width: 83, top: 124, left: 198 }, // 口 101
  { width: 57, top: 134, left: 183 },
  { width: 37, top: 135, left: 222 },
  { width: 58, top: 128, left: 212 },
  { width: 37, top: 131, left: 218 },

  { width: 51, top: 127, left: 214 }, // 口 106
  { width: 53, top: 126, left: 214 },
  { width: 37, top: 135, left: 222 },
  { width: 32, top: 131, left: 233 },

  { width: 95, top: 137, left: 144 }, // 口 110
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 20, top: 124, left: 222 }, // 口 115
  { width: 72, top: 131, left: 203 },
  { width: 63, top: 113, left: 177 },
  { width: 27, top: 138, left: 222 },
  { width: 12, top: 135, left: 227 },

  { width: 20, top: 139, left: 222 }, // 口 120
  { width: 24, top: 139, left: 222 },
  { width: 24, top: 139, left: 214 },
  { width: 30, top: 131, left: 222 },
  { width: 14, top: 135, left: 227 },

  { width: 11, top: 138, left: 230 }, // 口 125
  { width: 52, top: 136, left: 205 },
  { width: 54, top: 133, left: 208 },
  { width: 14, top: 138, left: 231 },
  { width: 29, top: 134, left: 220 },

  { width: 57, top: 133, left: 205 }, // 口 130
  { width: 37, top: 119, left: 214 },
  { width: 46, top: 130, left: 196 },
  { width: 14, top: 126, left: 232 },
  { width: 28, top: 117, left: 226 },

  { width: 99, top: 121, left: 189 }, // 口 135
  { width: 18, top: 134, left: 221 },
  { width: 29, top: 131, left: 219 },
  { width: 16, top: 133, left: 228 },
  { width: 16, top: 131, left: 229 },

  { width: 32, top: 134, left: 220 }, // 口 140
  { width: 18, top: 132, left: 229 },
  { width: 91, top: 117, left: 194 },
  { width: 69, top: 124, left: 228 },
  { width: 81, top: 116, left: 197 },

  { width: 44, top: 126, left: 216 }, // 口 145
  { width: 56, top: 136, left: 206 },
  { width: 29, top: 115, left: 217 },
  { width: 94, top: 108, left: 152 },
  { width: 73, top: 125, left: 201 },

  { width: 42, top: 133, left: 216 }, // 口 150
  { width: 70, top: 137, left: 211 },
  { width: 69, top: 135, left: 204 },
  { width: 45, top: 128, left: 225 },
  { width: 25, top: 121, left: 223 },

  { width: 43, top: 134, left: 205 }, // 口 155
  { width: 42, top: 137, left: 208 },
  { width: 97, top: 112, left: 199 },
  { width: 55, top: 125, left: 192 },
  { width: 59, top: 132, left: 212 },

  { width: 57, top: 130, left: 206 }, // 口 160
  { width: 35, top: 139, left: 212 },
  { width: 44, top: 130, left: 214 },
  { width: 15, top: 135, left: 230 },
  { width: 11, top: 132, left: 230 },

  { width: 14, top: 139, left: 231 }, // 口 165
  { width: 95, top: 112, left: 197 },
  { width: 34, top: 139, left: 216 },
  { width: 65, top: 135, left: 207 },
  { width: 13, top: 136, left: 230 },

  { width: 94, top: 125, left: 192 }, // 口 170
  { width: 51, top: 135, left: 208 },
  { width: 54, top: 135, left: 212 },
  { width: 31, top: 140, left: 224 },
  { width: 33, top: 116, left: 223 },

  { width: 32, top: 136, left: 221 }, // 口 175
  { width: 23, top: 131, left: 227 },
  { width: 43, top: 121, left: 219 },
  { width: 31, top: 136, left: 224 },
  { width: 17, top: 136, left: 229 },

  { width: 38, top: 118, left: 220 }, // 口 180
  { width: 25, top: 131, left: 227 },
  { width: 83, top: 139, left: 204 },
  { width: 9, top: 120, left: 231 },
  { width: 29, top: 116, left: 223 },

  { width: 32, top: 139, left: 213 }, // 口 185
  { width: 29, top: 134, left: 228 },
  { width: 27, top: 135, left: 225 },
  { width: 14, top: 138, left: 232 },
  { width: 80, top: 132, left: 201 },

  { width: 52, top: 129, left: 198 }, // 口 190
  { width: 19, top: 139, left: 227 },
  { width: 37, top: 136, left: 200 },
  { width: 27, top: 138, left: 214 },
  { width: 22, top: 128, left: 223 },

  { width: 66, top: 125, left: 205 }, // 口 195
  { width: 45, top: 129, left: 219 },
  { width: 48, top: 129, left: 226 },
  { width: 11, top: 136, left: 233 },
  { width: 26, top: 138, left: 226 },
];

// 服
export const clothAppearanceInDisplayArea: FixedHeightAndPosition[] = [
  { height: 130, top: 155, left: 200 },
  { height: 130, top: 155, left: 200 },
  { height: 130, top: 155, left: 200 },
  { height: 130, top: 154, left: 190 },
  { height: 130, top: 154, left: 190 },
  { height: 130, top: 154, left: 190 },
  { height: 128, top: 154, left: 190 },
  { height: 129, top: 155, left: 201 },
  { height: 129, top: 155, left: 201 },
  { height: 129, top: 155, left: 201 },

  //以下有料プロダクト
  { height: 130, top: 154, left: 190 },
  { height: 130, top: 154, left: 190 },
  { height: 130, top: 154, left: 190 },
  { height: 128, top: 154, left: 190 },
  { height: 126, top: 154, left: 199 },
  { height: 128, top: 155, left: 190 },
  { height: 128, top: 155, left: 190 },
  { height: 128, top: 155, left: 190 },
  { height: 127, top: 153, left: 191 },
  { height: 127, top: 153, left: 191 },

  { height: 127, top: 155, left: 191 },
  { height: 126, top: 155, left: 192 },
  { height: 127, top: 155, left: 191 },
  { height: 126, top: 155, left: 192 },
  { height: 135, top: 151, left: 184 },
  { height: 130, top: 155, left: 201 },
  { height: 133, top: 153, left: 188 },
  { height: 130, top: 152, left: 186 },
  { height: 130, top: 152, left: 186 },
  { height: 126, top: 155, left: 193 },

  { height: 126, top: 155, left: 193 },
  { height: 128, top: 153, left: 180 },
  { height: 128, top: 153, left: 180 },
  { height: 129, top: 153, left: 189 },
  { height: 130, top: 152, left: 182 },
  { height: 132, top: 153, left: 187 },
  { height: 126, top: 155, left: 181 },
  { height: 128, top: 154, left: 180 },
  { height: 130, top: 150, left: 192 },
  { height: 128, top: 154, left: 180 },

  { height: 117, top: 143, left: 180 },
  { height: 132, top: 148, left: 158 },
  { height: 132, top: 150, left: 210 },
  { height: 146, top: 133, left: 187 },
  { height: 126, top: 155, left: 195 },
  { height: 128, top: 152, left: 193 },
  { height: 128, top: 156, left: 197 },
  { height: 128, top: 155, left: 199 },
  { height: 127, top: 154, left: 194 },
  { height: 127, top: 154, left: 194 },

  { height: 145, top: 139, left: 184 },
  { height: 129, top: 154, left: 189 },
  { height: 127, top: 155, left: 189 },
  { height: 129, top: 153, left: 191 },
  { height: 127, top: 155, left: 192 },
  { height: 127, top: 154, left: 201 },
  { height: 127, top: 154, left: 201 },
  { height: 127, top: 154, left: 201 },
  { height: 127, top: 155, left: 175 },
  { height: 127, top: 154, left: 189 },

  { height: 127, top: 155, left: 179 },
  { height: 130, top: 154, left: 183 },
  { height: 141, top: 154, left: 181 },
  { height: 130, top: 149, left: 169 },
  { height: 130, top: 155, left: 179 },
  { height: 132, top: 153, left: 174 },
  { height: 132, top: 153, left: 178 },
  { height: 132, top: 153, left: 191 },
  { height: 132, top: 153, left: 191 },
  { height: 130, top: 156, left: 189 },

  { height: 131, top: 154, left: 189 },
  { height: 132, top: 153, left: 172 },
  { height: 130, top: 154, left: 182 },
  { height: 130, top: 154, left: 182 },
  { height: 130, top: 150, left: 192 },
  { height: 128, top: 152, left: 189 },
  { height: 130, top: 154, left: 171 },
  { height: 130, top: 154, left: 175 },
  { height: 130, top: 154, left: 185 },
  { height: 130, top: 154, left: 187 },

  { height: 130, top: 154, left: 191 },
  { height: 139, top: 154, left: 189 },
  { height: 140, top: 140, left: 167 },
  { height: 134, top: 149, left: 179 },
  { height: 130, top: 152, left: 182 },
  { height: 128, top: 154, left: 183 },
  { height: 129, top: 154, left: 191 },
  { height: 131, top: 151, left: 187 },
  { height: 133, top: 149, left: 183 },
  { height: 128, top: 154, left: 145 },

  { height: 130, top: 150, left: 171 },
  { height: 138, top: 150, left: 186 },
  { height: 138, top: 150, left: 186 },
  { height: 182, top: 85, left: 136 },
  { height: 138, top: 150, left: 196 },
  { height: 136, top: 151, left: 195 },
  { height: 137, top: 151, left: 187 },
  { height: 133, top: 151, left: 186 },
  { height: 133, top: 151, left: 191 },
  { height: 161, top: 123, left: 162 },

  { height: 129, top: 151, left: 188 },
  { height: 133, top: 152, left: 191 },
  { height: 134, top: 151, left: 191 },
  { height: 134, top: 151, left: 191 },
  { height: 142, top: 143, left: 179 }, //ok

  { height: 129, top: 151, left: 188 },
  { height: 123, top: 154, left: 191 },
  { height: 123, top: 156, left: 194 },
  { height: 129, top: 151, left: 191 },
  { height: 129, top: 151, left: 171 },
  { height: 126, top: 154, left: 191 },

  { height: 138, top: 152, left: 186 }, // 112
  { height: 138, top: 152, left: 186 },
  { height: 139, top: 155, left: 195 },
  { height: 139, top: 155, left: 195 },
  { height: 136, top: 155, left: 202 },
  { height: 136, top: 155, left: 202 },
  { height: 145, top: 155, left: 202 },
  { height: 140, top: 143, left: 168 },

  { height: 138, top: 152, left: 186 }, // 服 120
  { height: 138, top: 152, left: 186 },
  { height: 139, top: 155, left: 173 },
  { height: 139, top: 155, left: 173 },
  { height: 136, top: 155, left: 173 },
  { height: 136, top: 155, left: 169 },
  { height: 148, top: 148, left: 116 },
  { height: 140, top: 154, left: 189 },

  { height: 138, top: 152, left: 160 }, // 服 128
  { height: 138, top: 152, left: 160 },
  { height: 138, top: 152, left: 129 },
  { height: 138, top: 152, left: 126 },
  { height: 131, top: 152, left: 199 },
  { height: 131, top: 152, left: 199 },
  { height: 131, top: 152, left: 199 },
  { height: 131, top: 152, left: 199 },

  { height: 109, top: 152, left: 177 }, // 服 136
  { height: 138, top: 152, left: 195 },
  { height: 161, top: 132, left: 137 },
  { height: 129, top: 148, left: 150 },
  { height: 286, top: 5, left: 91 },

  { height: 147, top: 145, left: 160 }, // 服 141
  { height: 134, top: 152, left: 190 },
  { height: 131, top: 152, left: 153 },
  { height: 179, top: 101, left: 142 },
  { height: 179, top: 101, left: 161 },

  { height: 147, top: 133, left: 180 }, // 服 146
  { height: 134, top: 152, left: 168 },
  { height: 148, top: 133, left: 161 },
  { height: 139, top: 143, left: 160 },
  { height: 188, top: 91,  left: 153 },

  { height: 126, top: 154, left: 180 }, // 服 151
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 150, top: 131, left: 169 }, // 服 156
  { height: 126, top: 154, left: 189 },
  { height: 134, top: 146, left: 182 },
  { height: 158, top: 130, left: 135 },
  { height: 130, top: 153,  left: 190 },

  { height: 152, top: 129, left: 181 }, // 服 160
  { height: 126, top: 154, left: 189 },
  { height: 129, top: 150, left: 186 },
  { height: 154, top: 127, left: 167 },
  { height: 130, top: 152,  left: 147 },

  { height: 144, top: 137, left: 155 }, // 服 166
  { height: 206, top: 78, left: 129 },
  { height: 147, top: 138, left: 151 },
  { height: 178, top: 101, left: 152 },
  { height: 247, top: 33,  left: 169 },

  { height: 199, top: 82, left: 140 }, // 服 171
  { height: 128, top: 153, left: 155 },
  { height: 147, top: 134, left: 155 },
  { height: 141, top: 141, left: 134 },
  { height: 200, top: 82,  left: 134 },

  { height: 132, top: 148, left: 164 }, // 服 176
  { height: 180, top: 101, left: 127 },
  { height: 132, top: 148, left: 154 },
  { height: 137, top: 148, left: 159 },
  { height: 142, top: 143,  left: 146 },

  { height: 128, top: 153, left: 199 }, // 服 181
  { height: 123, top: 155, left: 147 },
  { height: 125, top: 156, left: 160 },
  { height: 124, top: 156, left: 190 },
  { height: 198, top: 92,  left: 151 },

  { height: 143, top: 136, left: 140 }, // 服 186
  { height: 195, top: 85, left: 180 },
  { height: 123, top: 161, left: 173 },
  { height: 151, top: 132, left: 170 },
  { height: 126, top: 155,  left: 172 },

  { height: 134, top: 150, left: 162 }, // 服 191
  { height: 146, top: 134, left: 137 },
  { height: 128, top: 153, left: 184 },
  { height: 108, top: 152, left: 174 },
  { height: 160, top: 123,  left: 110 },

  { height: 139, top: 141, left: 144 }, // 服 196
  { height: 100, top: 156, left: 171 },
  { height: 128, top: 153, left: 165 },
  { height: 195, top: 92, left: 129 },
  { height: 131, top: 149,  left: 163 },

  { height: 168, top: 113, left: 135 }, // 服 201
  { height: 144, top: 138, left: 147 },
  { height: 181, top: 99, left: 126 },
  { height: 131, top: 153, left: 202 },
  { height: 159, top: 121,  left: 138 },

  { height: 177, top: 104, left: 147 }, // 服 206
  { height: 129, top: 153, left: 188 },
  { height: 161, top: 136, left: 163 },
  { height: 137, top: 143, left: 172 },
  { height: 144, top: 130,  left: 138 },

  { height: 136, top: 146, left: 157 }, // 服 211
  { height: 131, top: 153, left: 153 },
  { height: 143, top: 152, left: 171 },
  { height: 131, top: 151, left: 172 },
  { height: 224, top: 76,  left: 151 },

  { height: 143, top: 137, left: 143 }, // 服 216
  { height: 132, top: 147, left: 156 },
  { height: 129, top: 152, left: 175 },
  { height: 178, top: 101, left: 149 },
  { height: 139, top: 143,  left: 141 },

  { height: 131, top: 153, left: 169 }, // 服 221
  { height: 132, top: 152, left: 180 },
  { height: 140, top: 142, left: 172 },
  { height: 160, top: 123, left: 120 },
  { height: 186, top: 108,  left: 118 },

  { height: 173, top: 108, left: 158 }, // 服 226
  { height: 132, top: 151, left: 164 },
  { height: 123, top: 152, left: 172 },
  { height: 210, top: 70, left: 149 },
  { height: 195, top: 84,  left: 113 },

  { height: 126, top: 156, left: 148 }, // 服 231
  { height: 208, top: 72, left: 147 },
  { height: 177, top: 105, left: 184 },
  { height: 144, top: 125, left: 146 },
  { height: 140, top: 143,  left: 140 },

  { height: 139, top: 141, left: 165 }, // 服 236
  { height: 148, top: 131, left: 144 },
  { height: 129, top: 152, left: 171 },
  { height: 133, top: 147, left: 176 },
  { height: 144, top: 144,  left: 156 },
];

// 髪
export const hairAppearanceInDisplayArea: FixedWidthAndPosition[] = [
  { width: 118, top: 33, left: 190 },
  { width: 118, top: 35, left: 190 },
  { width: 115, top: 35, left: 193 },
  { width: 178, top: 37, left: 166 },
  { width: 120, top: 33, left: 186 },
  { width: 120, top: 35, left: 190 },
  { width: 118, top: 36, left: 190 },
  { width: 108, top: 39, left: 195 },
  { width: 112, top: 38, left: 192 },
  { width: 117, top: 34, left: 191 },

  { width: 114, top: 36, left: 190 },
  { width: 132, top: 39, left: 183 },
  { width: 128, top: 36, left: 188 },
  { width: 136, top: 40, left: 190 },
  { width: 116, top: 37, left: 190 },
  { width: 118, top: 37, left: 190 },
  { width: 124, top: 35, left: 182 },
  { width: 119, top: 35, left: 188 },
  { width: 111, top: 35, left: 195 },
  { width: 134, top: 35, left: 175 },

  // 以下有料プロダクト
  { width: 132, top: 39, left: 184 },
  { width: 114, top: 36, left: 192 },
  { width: 109, top: 36, left: 192 },
  { width: 127, top: 35, left: 185 },
  { width: 111, top: 37, left: 193 },
  { width: 111, top: 37, left: 192 },
  { width: 122, top: 35, left: 185 },
  { width: 108, top: 21, left: 192 },
  { width: 122, top: 35, left: 196 },
  { width: 115, top: 36, left: 194 },

  { width: 125, top: 40, left: 191 },
  { width: 120, top: 24, left: 188 },
  { width: 108, top: 35, left: 195 },
  { width: 116, top: 36, left: 184 },
  { width: 100, top: 18, left: 196 },
  { width: 130, top: 38, left: 182 },
  { width: 130, top: 38, left: 185 },
  { width: 112, top: 35, left: 193 },
  { width: 112, top: 35, left: 193 },
  { width: 122, top: 36, left: 188 },

  { width: 119, top: 28, left: 191 },
  { width: 144, top: 36, left: 180 },
  { width: 130, top: 18, left: 196 },
  { width: 120, top: 39, left: 193 },
  { width: 128, top: 33, left: 185 },
  { width: 110, top: 33, left: 192 },
  { width: 111, top: 37, left: 194 },
  { width: 107, top: 33, left: 195 },
  { width: 116, top: 33, left: 186 },
  { width: 90, top: 32, left: 228 },

  { width: 156, top: 39, left: 178 },
  { width: 102, top: 36, left: 197 },
  { width: 102, top: 38, left: 201 },
  { width: 125, top: 38, left: 189 },
  { width: 116, top: 36, left: 188 },
  { width: 128, top: 16, left: 186 },
  { width: 128, top: 18, left: 186 },
  { width: 124, top: 28, left: 182 },
  { width: 126, top: 32, left: 186 },
  { width: 110, top: 36, left: 192 },

  { width: 110, top: 36, left: 192 },
  { width: 132, top: 34, left: 179 },
  { width: 120, top: 39, left: 189 },
  { width: 118, top: 29, left: 189 },
  { width: 154, top: 37, left: 174 },
  { width: 109, top: 30, left: 191 },
  { width: 126, top: 32, left: 186 },
  { width: 113, top: 36, left: 188 },
  { width: 133, top: 34, left: 179 },
  { width: 133, top: 33, left: 186 },

  { width: 115, top: 34, left: 188 },
  { width: 128, top: 31, left: 185 },
  { width: 132, top: 31, left: 178 },
  { width: 122, top: 30, left: 186 },
  { width: 160, top: 25, left: 192 },

  { width: 144, top: 35, left: 177 },
  { width: 133, top: 35, left: 184 },
  { width: 143, top: 27, left: 177 },
  { width: 122, top: 31, left: 178 },
  { width: 131, top: 32, left: 184 },

  { width: 134, top: 15, left: 182 },
  { width: 156, top: 38, left: 172 },
  { width: 139, top: 39, left: 179 },
  { width: 117, top: 38, left: 189 },
  { width: 144, top: 35, left: 162 },

  { width: 132, top: 23, left: 182 }, //86
  { width: 143, top: 19, left: 189 },
  { width: 146, top: 35, left: 178 },
  { width: 139, top: 35, left: 179 },
  { width: 128, top: 32, left: 189 },

  { width: 132, top: 29, left: 182 }, // 髪91
  { width: 143, top: 30, left: 176 },
  { width: 146, top: 35, left: 176 },
  { width: 130, top: 35, left: 183 },
  { width: 128, top: 36, left: 187 },

  { width: 132, top: 29, left: 182 }, // 髪96
  { width: 132, top: 29, left: 182 },
  { width: 175, top: 34, left: 165 },
  { width: 140, top: 0, left: 179 },
  { width: 145, top: 17, left: 175 },

  { width: 162, top: 31, left: 165 }, // 髪101
  { width: 162, top:  4, left: 165 },
  { width: 153, top: 26, left: 174 },
  { width: 111, top: 29, left: 199 },
  { width: 140, top: 26, left: 178 },

  { width: 227, top: 35, left: 129 }, // 髪106
  { width: 167, top:  9, left: 161 },
  { width: 245, top: 20, left: 131 },
  { width: 132, top: 35, left: 180 },
  { width: 157, top: 24, left: 169 },

  { width: 221, top: 33, left: 134 }, // 髪111
  { width: 140, top: 39, left: 179 },
  { width: 121, top: 36, left: 191 },
  { width: 132, top: 35, left: 180 },
  { width: 164, top: 43, left: 162 },

  { width: 138, top: 36, left: 191 }, // 髪116
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 175, top: 38, left: 167 }, // 髪121
  { width: 128, top: 37, left: 178 },
  { width: 155, top: 39, left: 174 },
  { width: 143, top: 37, left: 176 },
  { width: 170, top: 25, left: 184 },

  { width: 146, top: 38, left: 184 }, // 髪126
  { width: 202, top: 16, left: 160 },
  { width: 148, top: 36, left: 177 },
  { width: 139, top: 40, left: 184 },
  { width: 162, top: 33, left: 169 },

  { width: 116, top: -11, left: 192 }, // 髪131
  { width: 192, top: -2, left: 152 },
  { width: 149, top: 36, left: 177 },
  { width: 130, top: 27, left: 183 },
  { width: 211, top: 37, left: 143 },

  { width: 143, top: 21, left: 169 }, // 髪136
  { width: 175, top: 8, left: 161 },
  { width: 157, top: 37, left: 156 },
  { width: 197, top: 17, left: 149 },
  { width: 134, top: 36, left: 187 },

  { width: 180, top: 24, left: 158 }, // 髪141
  { width: 137, top: 32, left: 179 },
  { width: 154, top: 17, left: 177 },
  { width: 140, top: 35, left: 174 },
  { width: 129, top: 39, left: 180 },

  { width: 220, top: 37, left: 138 }, // 髪146
  { width: 172, top: 8, left: 163 },
  { width: 171, top: 28, left: 162 },
  { width: 117, top: 16, left: 188 },
  { width: 133, top: 37, left: 176 },

  { width: 200, top: 38, left: 149 }, // 髪151
  { width: 148, top: 39, left: 173 },
  { width: 206, top: 28, left: 167 },
  { width: 135, top: 23, left: 183 },
  { width: 149, top: 36, left: 175 },

  { width: 120, top: 18, left: 189 }, // 髪156
  { width: 163, top: 10, left: 173 },
  { width: 193, top: 32, left: 153 },
  { width: 124, top: 34, left: 190 },
  { width: 171, top: -32, left: 156 },

  { width: 144, top: 23, left: 174 }, // 髪161
  { width: 141, top: 32, left: 178 },
  { width: 131, top: 26, left: 182 },
  { width: 154, top: 36, left: 184 },
  { width: 193, top: 31, left: 156 },

  { width: 153, top: 66, left: 170 }, // 髪166
  { width: 184, top: 18, left: 159 },
  { width: 153, top: 29, left: 173 },
  { width: 156, top: 31, left: 173 },
  { width: 56, top: 12, left: 219 },

  { width: 122, top: 36, left: 191 }, // 髪171
  { width: 124, top: 38, left: 186 },
  { width: 188, top: 36, left: 158 },
  { width: 186, top: 35, left: 159 },
  { width: 156, top: 32, left: 169 },

  { width: 142, top: 36, left: 181 }, // 髪176
  { width: 169, top: 36, left: 166 },
  { width: 165, top: 0, left: 165 },
  { width: 129, top: 38, left: 184 },
  { width: 133, top: 13, left: 187 },

  { width: 155, top: 9, left: 170 }, // 髪181
  { width: 164, top: 36, left: 164 },
  { width: 163, top: 22, left: 166 },
  { width: 110, top: 22, left: 190 },
  { width: 194, top: 38, left: 150 },

  { width: 145, top: 38, left: 183 }, // 髪186
  { width: 119, top: 33, left: 191 },
  { width: 166, top: 10, left: 165 },
  { width: 166, top: 23, left: 166 },
  { width: 194, top: 22, left: 144 },

  { width: 195, top: 38, left: 150 }, // 髪191
  { width: 170, top: 9, left: 162 },
  { width: 146, top: 39, left: 183 },
  { width: 151, top: -8, left: 187 },
  { width: 148, top: 20, left: 174 },

  { width: 161, top: 38, left: 163 }, // 髪196
  { width: 196, top: 39, left: 153 },
  { width: 205, top: 38, left: 149 },
  { width: 163, top: -2, left: 168 },
  { width: 154, top: 36, left: 170 },


  { width: 121, top: 39, left: 187 }, // 髪201
  { width: 137, top: 35, left: 175 },
  { width: 170, top: 40, left: 161 },
  { width: 151, top: 20, left: 170 },
  { width: 166, top: 33, left: 170 },
];

// 帽子
export const hatAppearanceInDisplayArea: FixedWidthAndPosition[] = [
  { width: 116, top: 26, left: 188 },
  { width: 116, top: 26, left: 188 },
  { width: 118, top: 22, left: 189 },
  { width: 118, top: 22, left: 189 },
  { width: 130, top: 24, left: 174 },
  { width: 130, top: 24, left: 174 },
  // 以下有料プロダクト
  { width: 132, top: 20, left: 186 },
  { width: 132, top: 20, left: 186 },
  { width: 152, top: 12, left: 174 },
  { width: 104, top: 48, left: 195 },

  { width: 134, top: 33, left: 178 },
  { width: 108, top: 48, left: 198 },
  { width: 122, top: 48, left: 187 },
  { width: 125, top: 40, left: 184 },
  { width: 125, top: 40, left: 184 },
  { width: 104, top: 48, left: 190 },
  { width: 104, top: 48, left: 190 },
  { width: 138, top: 2, left: 185 },
  { width: 138, top: 2, left: 185 },
  { width: 108, top: 40, left: 186 },

  { width: 108, top: 40, left: 186 },
  { width: 82, top: 48, left: 202 },
  { width: 152, top: 18, left: 188 },
  { width: 114, top: 22, left: 194 },
  { width: 198, top: 20, left: 149 },
  { width: 130, top: 30, left: 186 },
  { width: 144, top: 2, left: 182 },
  { width: 132, top: 20, left: 187 },
  { width: 120, top: 0, left: 185 },
  { width: 100, top: 10, left: 196 },

  { width: 130, top: 52, left: 180 },
  { width: 157, top: -4, left: 180 },
  { width: 164, top: 55, left: 161 },
  { width: 142, top: 9, left: 189 },
  { width: 126, top: 24, left: 180 },
  { width: 64, top: 8, left: 189 },
  { width: 118, top: 19, left: 188 },
  { width: 54, top: 37, left: 250 },
  { width: 60, top: 41, left: 250 },
  { width: 128, top: 66, left: 193 },

  { width: 126, top: 66, left: 181 },
  { width: 100, top: 62, left: 197 },
  { width: 46, top: 60, left: 248 },
  { width: 140, top: 23, left: 179 },
  { width: 160, top: 15, left: 168 },
  { width: 130, top: 8, left: 184 },
  { width: 130, top: 12, left: 174 },
  { width: 160, top: 0, left: 170 },
  { width: 80, top: 21, left: 212 },
  { width: 110, top: 46, left: 190 },

  { width: 144, top: 1, left: 180 },
  { width: 110, top: 26, left: 190 },
  { width: 119, top: 0, left: 189 },
  { width: 116, top: 26, left: 188 },
  { width: 124, top: 32, left: 188 },
  { width: 122, top: 28, left: 190 },
  { width: 140, top: 8, left: 197 },
  { width: 188, top: 24, left: 155 },
  { width: 80, top: 13, left: 230 },
  { width: 140, top: 6, left: 182 },

  { width: 126, top: 16, left: 186 },
  { width: 140, top: 12, left: 184 },
  { width: 152, top: 18, left: 174 },
  { width: 132, top: 11, left: 180 },
  { width: 174, top: 11, left: 168 },
  { width: 140, top: 20, left: 168 },
  { width: 170, top: -4, left: 162 },
  { width: 154, top: -10, left: 172 },
  { width: 192, top: 18, left: 156 },
  { width: 116, top: 20, left: 200 },

  { width: 126, top: 12, left: 180 },
  { width: 97, top: 10, left: 200 },
  { width: 112, top: 6, left: 190 },
  { width: 116, top: 40, left: 192 },
  { width: 170, top: 10, left: 163 },
  { width: 119, top: 0, left: 187 },
  { width: 90, top: 36, left: 190 },
  { width: 87, top: -7, left: 213 },
  { width: 87, top: -30, left: 208 },
  { width: 173, top: -4, left: 175 },
  { width: 158, top: -1, left: 165 },
  { width: 160, top: -1, left: 165 },
  { width: 153, top: 9, left: 161 },
  { width: 171, top: 0, left: 167 },
  
  { width: 164, top: 23, left: 164 },
  { width: 104, top: 38, left: 189 },
  { width: 101, top: 40, left: 194 },
  { width: 123, top: 34, left: 180 },
  { width: 118, top: 30, left: 197 },

  { width: 127, top: 20, left: 176 },
  { width: 136, top: 9,  left: 175 },
  { width: 140, top: 5, left: 177 },
  { width: 105, top: 14, left: 195 },
  { width: 135, top: 11, left: 184 },

  { width: 123, top: 11, left: 190 }, //95
  { width: 141, top: 73, left: 191 },
  { width: 137, top: 15, left: 172 },
  { width: 174, top: -48,left: 149 },
  { width: 159, top: 28, left: 165 },

  { width: 147, top: 24, left: 172 }, //帽子 100
  { width: 147, top: 24, left: 172 },
  { width: 109, top: 29, left: 191 },
  { width: 124, top: 18, left: 190 },
  { width: 144, top: 18, left: 174 },

  { width: 73, top: 25, left: 260 }, //帽子 105
  { width: 73, top: 25, left: 260 },
  { width: 147, top: 24, left: 172 },
  { width: 147, top: 24, left: 172 },
  { width: 91, top: 12, left: 223 },
  { width: 91, top: 12, left: 223 },
  { width: 91, top: 65, left: 193 },

  { width: 104, top: -30, left: 209 }, //帽子 112
  { width: 117, top: 28, left: 189 },
  { width: 145, top: 24, left: 177 },
  { width: 143, top: 0, left: 172 },
  { width: 144, top: -8, left: 167 },

  { width: 135, top: 44, left: 180 }, //帽子 117
  { width: 117, top: 28, left: 189 },
  { width: 181, top: 30, left: 157 },
  { width: 98, top: -8, left: 220 },
  { width: 172, top: -15, left: 159 },

  { width: 153, top: 62, left: 175 }, //帽子 122
  { width: 160, top: 11, left: 155 },
  { width: 181, top: 4, left: 157 },
  { width: 98, top: 37, left: 196 },
  { width: 133, top: -7, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 127
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 38, top: 0, left: 223 }, //帽子 132
  { width: 157, top: 22, left: 191 },
  { width: 135, top: 16, left: 179 },
  { width: 103, top: 10, left: 188 },
  { width: 171, top: 15, left: 174 },

  { width: 133, top: 0, left: 173 }, //帽子 137
  { width: 142, top: 32, left: 193 },
  { width: 141, top: 50, left: 195 },
  { width: 134, top: 10, left: 173 },
  { width: 43, top: 17, left: 224 },

  { width: 123, top: 6, left: 183 }, //帽子 142
  { width: 78, top: 23, left: 243 },
  { width: 91, top: -8, left: 260 },
  { width: 95, top: 2, left: 156 },
  { width: 169, top: -37, left: 154 },

  { width: 133, top: -1, left: 181 }, //帽子 147
  { width: 161, top: 11, left: 177 },
  { width: 179, top: 6, left: 164 },
  { width: 165, top: 22, left: 161 },
  { width: 229, top: -22, left: 134 },

  { width: 133, top: 34, left: 187 }, //帽子 152
  { width: 134, top: -34, left: 181 },
  { width: 162, top: 20, left: 174 },
  { width: 141, top: 22, left: 175 },
  { width: 149, top: 29, left: 186 },

  { width: 143, top: 1, left: 170 }, //帽子 157
  { width: 136, top: 6, left: 172 },
  { width: 162, top: 22, left: 159 },
  { width: 141, top: -1, left: 175 },
  { width: 120, top: 2, left: 186 },

  { width: 38, top: 58, left: 272 }, //帽子 162
  { width: 128, top: -8, left: 179 },
  { width: 140, top: 29, left: 178 },
  { width: 211, top: -18, left: 145 },
  { width: 178, top: 11, left: 157 },

  { width: 142, top: 25, left: 172 }, //帽子 167
  { width: 242, top: 3, left: 128 },
  { width: 120, top: 45, left: 192 },
  { width: 102, top: -7, left: 194 },
  { width: 118, top: -5, left: 187 },

  { width: 138, top: -16, left: 176 }, //帽子 172
  { width: 145, top: 1, left: 174 },
  { width: 123, top: 23, left: 185 },
  { width: 76, top: 35, left: 254 },
  { width: 138, top: 18, left: 176 },

  { width: 48, top: 29, left: 243 }, //帽子 177
  { width: 81, top: 18, left: 216 },
  { width: 111, top: 16, left: 200 },
  { width: 113, top: 19, left: 208 },
  { width: 171, top: 12, left: 165 },

  { width: 131, top: 29, left: 183 }, //帽子 182
  { width: 170, top: 28, left: 175 },
  { width: 174, top: 4, left: 160 },
  { width: 115, top: 19, left: 192 },
  { width: 176, top: 1, left: 158 },

  { width: 113, top: 6, left: 189 }, //帽子 187
  { width: 140, top: 18, left: 175 },
  { width: 104, top: 40, left: 190 },
  { width: 25, top: 29, left: 272 },
  { width: 136, top: -14, left: 198 },

  { width: 163, top: 15, left: 175 }, //帽子 192
  { width: 128, top: 11, left: 190 },
  { width: 194, top: -13, left: 150 },
  { width: 90, top: 2, left: 199 },
  { width: 197, top: 12, left: 148 },

  { width: 143, top: 15, left: 178 }, //帽子 197
  { width: 91, top: 9, left: 204 },
  { width: 144, top: 9, left: 173 },
  { width: 157, top: 24, left: 163 },
  { width: 177, top: 52, left: 162 },

  { width: 133, top: -38, left: 183 }, //帽子 202
  { width: 141, top: 33, left: 174 },
  { width: 115, top: -6, left: 189 },
  { width: 197, top: 35, left: 148 },
  { width: 217, top: 16, left: 142 },

  { width: 142, top: 25, left: 171 }, //帽子 207
  { width: 111, top: 13, left: 195 },
  { width: 109, top: 12, left: 195 },
  { width: 187, top: 35, left: 160 },
  { width: 195, top: 6, left: 152 },

  { width: 149, top: 37, left: 170 }, //帽子 212
  { width: 152, top: 36, left: 183 },
  { width: 139, top: 33, left: 174 },
  { width: 129, top: 36, left: 182 },
  { width: 235, top: 15, left: 136 },

];
