import React, { useMemo } from 'react';
import { t } from 'i18next';
import styled from 'styled-components';
import { MQ_DESKTOP } from 'javascript/components/landingPage/lib/MQ';
import { useLanguageStoreValue } from 'javascript/components/landingPage/store/language';
import RoomContent, {
  RoomItem,
} from 'javascript/components/landingPage/components/RoomContent';

// @ts-ignore
const ASSETS_VIDEO_ICON = RailsAssetPath('lp/video/video_icon.png');
// @ts-ignore
const ASSETS_VOICE_ICON = RailsAssetPath('lp/voice/voice_icon.png');
// @ts-ignore
const ASSETS_TIME_ICON = RailsAssetPath('lp/normal/time_icon.png');
// @ts-ignore
const ASSETS_REAL_WORLD_ICON = RailsAssetPath(
  'lp/realworld/realworld_icon.png'
);
// @ts-ignore
const ASSETS_VIDEO_IMG01 = RailsAssetPath('lp/video/img01.png');
// @ts-ignore
const ASSETS_VIDEO_IMG02 = RailsAssetPath('lp/video/img02.png');
// @ts-ignore
const ASSETS_VIDEO_IMG03 = RailsAssetPath('lp/video/img03.png');
// @ts-ignore
const ASSETS_VIDEO_IMG04 = RailsAssetPath('lp/video/img04.png');
// @ts-ignore
const ASSETS_VIDEO_IMG01_PC = RailsAssetPath('lp/video/img01_pc.png');
// @ts-ignore
const ASSETS_VIDEO_IMG02_PC = RailsAssetPath('lp/video/img02_pc.png');
// @ts-ignore
const ASSETS_VIDEO_IMG03_PC = RailsAssetPath('lp/video/img03_pc.png');
// @ts-ignore
const ASSETS_VIDEO_IMG04_PC = RailsAssetPath('lp/video/img04_pc.png');
// @ts-ignore
const ASSETS_VIDEO_IMG05_PC = RailsAssetPath('lp/video/img05_pc.png');
// @ts-ignore
const ASSETS_VOICE_IMG01 = RailsAssetPath('lp/voice/img01.png');
// @ts-ignore
const ASSETS_VOICE_IMG02 = RailsAssetPath('lp/voice/img02.png');
// @ts-ignore
const ASSETS_VOICE_IMG03 = RailsAssetPath('lp/voice/img03.png');
// @ts-ignore
const ASSETS_VOICE_IMG01_PC = RailsAssetPath('lp/voice/img01_pc.png');
// @ts-ignore
const ASSETS_VOICE_IMG02_PC = RailsAssetPath('lp/voice/img02_pc.png');
// @ts-ignore
const ASSETS_VOICE_IMG03_PC = RailsAssetPath('lp/voice/img03_pc.png');
// @ts-ignore
const ASSETS_NORMAL_IMG01 = RailsAssetPath('lp/normal/img01.png');
// @ts-ignore
const ASSETS_NORMAL_IMG02 = RailsAssetPath('lp/normal/img02.png');
// @ts-ignore
const ASSETS_NORMAL_IMG03 = RailsAssetPath('lp/normal/img03.png');
// @ts-ignore
const ASSETS_NORMAL_IMG01_PC = RailsAssetPath('lp/normal/img01_pc.png');
// @ts-ignore
const ASSETS_NORMAL_IMG02_PC = RailsAssetPath('lp/normal/img02_pc.png');
// @ts-ignore
const ASSETS_NORMAL_IMG03_PC = RailsAssetPath('lp/normal/img03_pc.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG01 = RailsAssetPath('lp/realworld/img01.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG02 = RailsAssetPath('lp/realworld/img02.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG03 = RailsAssetPath('lp/realworld/img03.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG04 = RailsAssetPath('lp/realworld/img04.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG05 = RailsAssetPath('lp/realworld/img05.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG01_PC = RailsAssetPath('lp/realworld/img01_pc.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG02_PC = RailsAssetPath('lp/realworld/img02_pc.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG03_PC = RailsAssetPath('lp/realworld/img03_pc.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG04_PC = RailsAssetPath('lp/realworld/img04_pc.png');
// @ts-ignore
const ASSETS_REAL_WORLD_IMG05_PC = RailsAssetPath('lp/realworld/img05_pc.png');

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
  }
`;

export interface Room {
  video: string;
  voice: string;
  normal: string;
  real: string;
}

export const ROOM = {
  video: 'VIDEO',
  voice: 'VOICE',
  normal: 'NORMAL',
  real: 'REAL',
};

const RoomWrapper = () => {
  const languageStoreValue = useLanguageStoreValue();

  // video
  const videoItems: RoomItem = useMemo(() => {
    return {
      name: ROOM.video,
      icon: ASSETS_VIDEO_ICON,
      title: t('video.title'),
      subtitle: t('video.subtitle'),
      text: t('video.text'),
      images: [
        ASSETS_VIDEO_IMG01,
        ASSETS_VIDEO_IMG02,
        ASSETS_VIDEO_IMG03,
        ASSETS_VIDEO_IMG04,
      ],
      imagesPc: [
        ASSETS_VIDEO_IMG01_PC,
        ASSETS_VIDEO_IMG02_PC,
        ASSETS_VIDEO_IMG03_PC,
        ASSETS_VIDEO_IMG04_PC,
        ASSETS_VIDEO_IMG05_PC,
      ],
      slide: [
        t('video.list01'),
        t('video.list02'),
        t('video.list03'),
        t('video.list04'),
        t('video.list05'),
      ],
    };
  }, [languageStoreValue]);

  // voice
  // const voiceItems: RoomItem = useMemo(() => {
  //   return {
  //     name: ROOM.voice,
  //     icon: ASSETS_VOICE_ICON,
  //     title: t('voice.title'),
  //     subtitle: t('voice.subtitle'),
  //     text: t('voice.text'),
  //     images: [ASSETS_VOICE_IMG01, ASSETS_VOICE_IMG02, ASSETS_VOICE_IMG03],
  //     imagesPc: [
  //       ASSETS_VOICE_IMG01_PC,
  //       ASSETS_VOICE_IMG02_PC,
  //       ASSETS_VOICE_IMG03_PC,
  //     ],
  //     slide: [t('voice.list01'), t('voice.list02'), t('voice.list03')],
  //   };
  // }, [languageStoreValue]);

  // time
  const normalItems: RoomItem = useMemo(() => {
    return {
      name: ROOM.normal,
      icon: ASSETS_TIME_ICON,
      title: t('time.title'),
      subtitle: t('time.subtitle'),
      text: t('time.text'),
      images: [ASSETS_NORMAL_IMG01, ASSETS_NORMAL_IMG02, ASSETS_NORMAL_IMG03],
      imagesPc: [
        ASSETS_NORMAL_IMG01_PC,
        ASSETS_NORMAL_IMG02_PC,
        ASSETS_NORMAL_IMG03_PC,
      ],
      slide: [t('time.list01'), t('time.list02'), t('time.list03')],
    };
  }, [languageStoreValue]);

  // realWorld
  const realWorldItems: RoomItem = useMemo(() => {
    return {
      name: ROOM.real,
      icon: ASSETS_REAL_WORLD_ICON,
      title: t('real.title'),
      subtitle: t('real.subtitle'),
      text: t('real.text'),
      images: [
        ASSETS_REAL_WORLD_IMG01,
        ASSETS_REAL_WORLD_IMG02,
        ASSETS_REAL_WORLD_IMG03,
        ASSETS_REAL_WORLD_IMG04,
        ASSETS_REAL_WORLD_IMG05,
      ],
      imagesPc: [
        ASSETS_REAL_WORLD_IMG01_PC,
        ASSETS_REAL_WORLD_IMG02_PC,
        ASSETS_REAL_WORLD_IMG03_PC,
        ASSETS_REAL_WORLD_IMG04_PC,
        ASSETS_REAL_WORLD_IMG05_PC,
      ],
      slide: [
        t('real.list01'),
        t('real.list02'),
        t('real.list03'),
        t('real.list04'),
        t('real.list05'),
      ],
    };
  }, [languageStoreValue]);

  return (
    <Wrapper>
      <RoomContent items={videoItems} />
      {/* <RoomContent items={voiceItems} /> */}
      <RoomContent items={normalItems} />
      <RoomContent items={realWorldItems} />
    </Wrapper>
  );
};

export default RoomWrapper;
