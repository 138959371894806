import styled from "styled-components";
import { sp } from "../../utils/mediaQuery";

export const __VoiceChatButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BCBEC0;
  border-radius: 8px;
  background: #fff;
  padding: 5px 10px;
  height: 28px;
  width: 64px;
  margin-left: 16px;
  cursor: pointer;
  ${sp`
    margin-left: 12px;
    width: 44px;
    height: 20px;
    padding: 5px 8px;
    border-width: 1px;
    border-radius: 4px;
  `}
  ${({ type }) => type == "point" && `
    width: 130px;
    box-shadow: none;
    align-items: center;
    justify-content: flex-end;
    border: none;
    border-radius: 0;
    // border-right: 2px solid #f6f6f6;
    cursor: default;
  `}
`

export const __VoiceChatButtonRow = styled.div`
  padding: 2px 10px;
  //min-width: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ type }) => type == "point" && `
    padding: 2px 4px;
  `}
`

//icons
export const __VoiceChatButtonVoice = styled.button`
  width: 24px;
  height: auto;
  ${sp`
    width: 20px;
  `}
`

export const __VoiceChatButtonSpeaker = styled.button`
  width: 20px;
  height: auto;
  ${sp`
    width: 17px;
  `}
`

export const __VoiceChatButtonMic = styled.button`
  width: 18px;
  img {
    height: 20px;
  }
  ${sp`
    width: 14px;
    img {
      height: 16px;
    }
  `}
`

export const __VoiceChatButtonReceiver = styled.button`
  width: 30px;
  height: auto;
  margin-right: 10px;
  ${sp`
    width: 25px;
  `}
`

export const __VoiceChatButtonPoint = styled.div`
  display: flex;
  color: #29a0e9;
  height: auto;
  align-items: center;
  img {
    width: 16px;
  }
`

export const __VoiceChatButtonPointText = styled.span`
  font-size: 10px;
  color: #29a0e9;
  font-size: 12px;
  padding-top: 5px;
  ${sp`
  `}
`

export const __VoiceChatButtonPointNumber = styled.div`
  display: flex;
  padding-left: 12px;
  letter-spacing: 1px;
  font-size: 14px;
`


export const __PointBuyButton = styled.div`
  width: 36px;
  margin-left: 5px;
  margin-right: 5px; 
  img {
    width: 18px;
    height: 18px;
  } 
  ${sp`
    display:none;
  `}
`