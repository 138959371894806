import React, { useState, useEffect, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import store from '../../lib/redux/store';
import styled from 'styled-components';
import { sp } from '../../utils/mediaQuery';
import { useMediaQuery } from 'react-responsive';
import { connect, useDispatch, useSelector } from 'react-redux';
import useVoiceContext from '../voice/hooks/useVoiceContext';
import { RealWorldTemplate } from '../../features/realWorld/components';
import { useGetOfficeData } from '../../features/realWorld/hooks/useGetOfficeData';
import { SubRooms } from '../../features/subRooms/components';
import { VirtualWorldTemplate } from '../../features/virtualWorld/components';
import { OfficeTitle } from '../../features/realWorld/components/OfficeTitle';
import { FreeSpaceTemplate } from 'javascript/features/freeSpace/components';
import { OpenChatInputArea } from 'javascript/features/freeSpace/components/OpenChatInputArea';
import { Trans } from 'react-i18next';
import useRoomState from '../voice/hooks/useRoomState';
import { setVoiceChatRoom } from 'javascript/lib/redux/actions/voiceChat';
import { useDirectCall } from 'javascript/components/voice/hooks/useDirectCall';
import { directCallChannel } from 'javascript/channels/direct_call_channel';
import { fetchUserData } from '../../lib/redux/actions/user';

const Office = ({ currentUser, created, roomId, videoConnectStatus }) => {
  const dispatch = useDispatch();

  const { virtualWorldImage, officeData } = useGetOfficeData();

  const isMobile = useMediaQuery({ maxWidth: 430 });
  const userId = currentUser.id;
  const currentAvatar = currentUser.avatar;
  const userImage = currentUser.image;
  const isCurrentAvatarInFreeSpace = currentUser.work_status === 'working';

  const { room, connect, leave, inviteInfo } = useVoiceContext();
  const [index, setIndex] = useState(1);
  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [isHiddenSubRoom, setIsHiddenSubRoom] = useState(false);
  const { errorCode } = useRoomState();
  const handleOnClickTriangle = useCallback(
    (e) => {
      e.stopPropagation();
      setIsHiddenSubRoom(!isHiddenSubRoom);
    },
    [setIsHiddenSubRoom, isHiddenSubRoom]
  );

  const plan = useSelector((state) => state.officePlan.plan);
  const isVideoOnline = videoConnectStatus();
  const { handleJoinDirectCall, handleLeaveDirectCall, handleDirectCallChannelRecieved, leaveRoom } = useDirectCall();
  const { subscribeDirectCallChannel } = directCallChannel();
  const directCallCable = subscribeDirectCallChannel(currentAvatar?.id);

  useEffect(() => {
    // ブラウザやウインドウを閉じる時に、音声通話をしていた場合は
    // room_leaveAPIを飛ばし、音声部屋から退出させる
    const disconnectIfRoomConnected = () => {
      if (room) {
        axios.post('/api/v1/twilio/voices/room_leave', {
          end_call: true,
        });
      }
    };

    window.addEventListener('beforeunload', disconnectIfRoomConnected);
  }, [room]);

  useEffect(() => {
    if (created && roomId) {
      startVoiceChat();
    }
  }, [created, roomId]);

  useEffect(() => {
    if (!room && token && roomName) {
      connect(token, roomName);
    }
  }, [token, roomName]);

  const updateVoiceChat = () => {
    const args = {
      created: false,
      roomId: null,
      twilioRoomId: null,
    };
    dispatch(setVoiceChatRoom(args));
  };

  const updateUserInfo = () => {
    store.dispatch(fetchUserData());
  };

  useEffect(() => {
    // PCスリープによりTwilioの接続がエラーになったら音声切断
    if (errorCode === 53001) {
      setToken(null);
      setRoomName(null);
      updateVoiceChat();
      if (room) {
        leave(room);
      }
    }
    // 2人で通話していた場合に片方がタブを閉じた際に発生するエラーが発生した場合、
    // 再度音声を接続する
    if (errorCode === 53405 && created && roomId !== null && !room) {
      startVoiceChat();
    }
  }, [errorCode, created, roomId, room]);

  useEffect(() => {
    if (!plan && officeData) {
      dispatch({ type: 'SET_OFFICE_PLAN', plan: officeData.office_plan });
    }
  }, [plan, officeData]);

  // 個別通話の通知を複数回受け取ってしまった場合、何度も接続にいってしまい同じユーザーが同じ部屋に多重ログインしてしまうため、レシーバだけはここで設定する
  useEffect(() => {
    directCallCable.received = (data) => handleDirectCallChannelRecieved(currentAvatar, data);
    return () => directCallCable.received = () => { };
  }, [])

  useEffect(() => {
    if (inviteInfo && Object.keys(inviteInfo).length) {
      handleJoinDirectCall(inviteInfo.to_avatar_id, inviteInfo.twilio_room_id);
    }
  }, [inviteInfo])


  useEffect(() => {
    // FormEnterRoomの更新では個別通時への可否が判定できなかったので、ここでもcurrentUserをアップデートする
    updateUserInfo();
  }, [room])

  const startVoiceChat = () => {
    const params = {
      remorks_room_id: roomId,
      twilio_room_id: null,
      invite_user_id: null,
    };
    getToken(params);
  };

  const getToken = (params) => {
    axios.post('/api/v1/twilio/voices/room_enter', params).then((res) => {
      setToken(res.data.token);
      setRoomName(res.data.room_name);
    });
  };



  if (!officeData) return null;

  const styles = {
    root: {
      paddingRight: index === 0 ? '32px' : '0',
    },
  };

  return (
    <>
      {isMobile && (
        // SPでタイトルごとスクロールしてしまうのを防ぐため、タイトルのみを切り出し、fixedを当てるようにしている
        <OfficeTitleMobileWrapper index={index}>
          {index < 0.5 ? (
            <OfficeTitle img='location.png' plan={plan} role={currentUser.role}>
              <Trans i18nKey='GenelalRoomReal' />
            </OfficeTitle>
          ) : (
            <OfficeTitle
              img='virtual-world.png'
              plan={plan}
              role={currentUser.role}
              theme={virtualWorldImage}
              isVirtual
              index={index}
            >
              <Trans i18nKey='GenelalRoomVirtual' />
            </OfficeTitle>
          )}
        </OfficeTitleMobileWrapper>
      )}
      <OfficeWrapper>
        {!isMobile ? (
          <>
            <div className='col-1'>
              <RealWorldTemplate
                realWorldList={officeData.reals}
                plan={plan}
                role={currentUser.role}
                videoConnectStatus={videoConnectStatus}
              />
              <SubRooms
                sleepRoom={officeData.day_off[0]}
                loungeRoom={officeData.sleep[0]}
                videoConnectStatus={videoConnectStatus}
                role={currentUser.role}
              />
            </div>
            <div className='col-2'>
              <VirtualWorldTemplate
                userId={userId}
                avatar={currentAvatar}
                userImage={userImage}
                virtualWorldList={officeData.virtuals}
                role={currentUser.role}
                plan={plan}
                image={virtualWorldImage}
                isVideoOnline={isVideoOnline}
              />
            </div>
          </>
        ) : (
          <>
            <SwipeableViews
              style={styles.root}
              onSwitching={(idx) => setIndex(idx)}
              index={index}
            >
              <div className='col-1'>
                <RealWorldTemplate
                  realWorldList={officeData.reals}
                  plan={plan}
                  isHiddenSubRoom={isHiddenSubRoom}
                  role={currentUser.role}
                  videoConnectStatus={videoConnectStatus}
                />
              </div>
              <StyledFreeSpaceWrapper background={virtualWorldImage}>
                <FreeSpaceTemplate
                  userImage={userImage}
                  avatar={currentAvatar}
                  isCast={currentUser.role == "cast"}
                />
              </StyledFreeSpaceWrapper>
              <div className='col-2'>
                <VirtualWorldTemplate
                  userId={userId}
                  avatar={currentAvatar}
                  userImage={userImage}
                  virtualWorldList={officeData.virtuals}
                  role={currentUser.role}
                  plan={plan}
                  image={virtualWorldImage}
                  isHiddenSubRoom={isHiddenSubRoom}
                  index={index}
                  isVideoOnline={isVideoOnline}
                />
              </div>
            </SwipeableViews>
            <SubRoomsWrapper>
              {index > 0.9 && index < 1.9 && (
                <OpenChatInputArea
                  isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
                  avatar={currentAvatar}
                  userImage={userImage}
                />
              )}
              <SubRooms
                sleepRoom={officeData.day_off[0]}
                loungeRoom={officeData.sleep[0]}
                handleOnClick={handleOnClickTriangle}
                isHiddenSubRoom={isHiddenSubRoom}
                videoConnectStatus={videoConnectStatus}
                role={currentUser.role}
              />
            </SubRoomsWrapper>
          </>
        )}
      </OfficeWrapper>
    </>
  );
};

const OfficeTitleMobileWrapper = styled.div`
  height: 34px;
  width: 100%;
  position: fixed;
  top: 44px;
  z-index: 1;
  background: #fff;
  padding: 0 10px 0 12px;
`;

const OfficeWrapper = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 0 10px 16px;
  height: calc(100vh - 111px);
  ${sp`
    width: auto;
    height: calc(100vh - 44px);
    margin-top: 33px;
    padding: 0;
    overflow: auto;
  `}
  .col-1 {
    width: 256px;
    ${sp`
      width: 100%;
      margin-right: 16px;
      padding-left: 10px;
    `}
  }
  .col-2 {
    width: calc(100% - 264px);
    ${sp`
      width: 100%;
    `}
  }
`;

const SubRoomsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const StyledFreeSpaceWrapper = styled.div`
  width: 100%;
  background-image: ${({ background }) =>
    background
      ? `url(${RailsAssetPath(`worldTheme/${background}.png`)})`
      : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
  overflow-x: hidden;
`;

const mapStateToProps = (state) => {
  return {
    created: state.voiceChat.created,
    roomId: state.voiceChat.roomId,
  };
};

export default connect(mapStateToProps)(Office);
