import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  jpStyle,
  buttonAnim,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { renderHTML } from 'javascript/utils/util';

// @ts-ignore
const ASSETS_IMG01_JA = RailsAssetPath('lp/price/price01_ja.png');
// @ts-ignore
const ASSETS_IMG01_EN = RailsAssetPath('lp/price/price01_en.png');
// @ts-ignore
const ASSETS_IMG01_DE = RailsAssetPath('lp/price/price01_de.png');
// @ts-ignore
const ASSETS_IMG01_ES = RailsAssetPath('lp/price/price01_es.png');
// @ts-ignore
const ASSETS_IMG01_KO = RailsAssetPath('lp/price/price01_ko.png');
// @ts-ignore
const ASSETS_IMG01_FR = RailsAssetPath('lp/price/price01_fr.png');
// @ts-ignore
const ASSETS_IMG01_IT = RailsAssetPath('lp/price/price01_it.png');
// @ts-ignore
const ASSETS_IMG01_PO = RailsAssetPath('lp/price/price01_pt.png');
// @ts-ignore
const ASSETS_IMG01_RU = RailsAssetPath('lp/price/price01_ru.png');
// @ts-ignore
const ASSETS_IMG01_CH_CN = RailsAssetPath('lp/price/price01_ch_cn.png');
// @ts-ignore
const ASSETS_IMG01_CH_TW = RailsAssetPath('lp/price/price01_ch_tw.png');
// @ts-ignore
const ASSETS_IMG02_JA = RailsAssetPath('lp/price/price02_ja.png');
// @ts-ignore
const ASSETS_IMG02_EN = RailsAssetPath('lp/price/price02_en.png');
// @ts-ignore
const ASSETS_IMG02_DE = RailsAssetPath('lp/price/price02_de.png');
// @ts-ignore
const ASSETS_IMG02_ES = RailsAssetPath('lp/price/price02_es.png');
// @ts-ignore
const ASSETS_IMG02_KO = RailsAssetPath('lp/price/price02_ko.png');
// @ts-ignore
const ASSETS_IMG02_FR = RailsAssetPath('lp/price/price02_fr.png');
// @ts-ignore
const ASSETS_IMG02_IT = RailsAssetPath('lp/price/price02_it.png');
// @ts-ignore
const ASSETS_IMG02_PO = RailsAssetPath('lp/price/price02_pt.png');
// @ts-ignore
const ASSETS_IMG02_RU = RailsAssetPath('lp/price/price02_ru.png');
// @ts-ignore
const ASSETS_IMG02_CH_CN = RailsAssetPath('lp/price/price02_ch_cn.png');
// @ts-ignore
const ASSETS_IMG02_CH_TW = RailsAssetPath('lp/price/price02_ch_tw.png');
// @ts-ignore
const ASSETS_IMG03_JA = RailsAssetPath('lp/price/price03_ja.png');
// @ts-ignore
const ASSETS_IMG03_EN = RailsAssetPath('lp/price/price03_en.png');
// @ts-ignore
const ASSETS_IMG03_DE = RailsAssetPath('lp/price/price03_de.png');
// @ts-ignore
const ASSETS_IMG03_ES = RailsAssetPath('lp/price/price03_es.png');
// @ts-ignore
const ASSETS_IMG03_KO = RailsAssetPath('lp/price/price03_ko.png');
// @ts-ignore
const ASSETS_IMG03_FR = RailsAssetPath('lp/price/price03_fr.png');
// @ts-ignore
const ASSETS_IMG03_IT = RailsAssetPath('lp/price/price03_it.png');
// @ts-ignore
const ASSETS_IMG03_PO = RailsAssetPath('lp/price/price03_pt.png');
// @ts-ignore
const ASSETS_IMG03_RU = RailsAssetPath('lp/price/price03_ru.png');
// @ts-ignore
const ASSETS_IMG03_CH_CN = RailsAssetPath('lp/price/price03_ch_cn.png');
// @ts-ignore
const ASSETS_IMG03_CH_TW = RailsAssetPath('lp/price/price03_ch_tw.png');

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
`;

const DetailWrapper = styled.div`
  width: 645px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
    width: 100vw;
  }
`;

const DetailBox = styled.div`
  margin-bottom: 40px;
  ${MQ_MOBILE} {
    margin-bottom: 30px;
  }
  
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding: 0 ${spp(20)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Subtitle = styled.h4`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  overflow: visible;
  color: #535353;
  margin-bottom: 10px;
  ${MQ_MOBILE} {
    font-size: 15px;
    padding: 0 16px;
  }
  ${MQ_DESKTOP} {
    font-size: 22px;
    line-height: ${pcPx(80)};
  }
`;

const ImageWrapper = styled.div`
  ${fadeInStyle}
  display: block;
`

const Img = styled.img`
  ${fadeInStyle}
  background-color: #fff;
  ${MQ_MOBILE} {
    padding: 0 16px;
  }
`;

const SubText = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  margin: 28px 0;
  ${MQ_MOBILE} {
    font-size: 13px;
    font-weight: bold;
    line-height: 22px;
    margin: 10px 0;
    padding: 0 16px;
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  font-size: 16px;
  line-height: 28px;
  margin-top: 24px;
  span {
    color: red;
    font-weight: bold;
  }
  ${MQ_MOBILE} {
    font-size: 13px;
    line-height: 22px;
    padding: 0 16px;
    margin-top: 14px;
  }
`;

const LinkWrapper = styled.div`
  ${fadeInStyle}
  display: flex;
  justify-content: center;
  transition-delay: 0.3s;
  margin: 36px 0 24px;
`;

const Link = styled.a`
  ${jpStyle}
  ${buttonAnim}
  color: ${BLACK};
  border: solid 1px ${BLACK};
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  ${MQ_MOBILE} {
    font-size: ${spp(25)};
    line-height: ${spp(43)};
    border-radius: ${spp(10)};
    padding: ${spp(21)} ${spp(95)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    border-radius: ${pcPx(10)};
    padding: ${pcPx(20)} ${pcPx(97)};
  }
`;

const TableWrapper = styled.div`
  ${fadeInStyle}
  display: block;
  border: 1px solid #a8a8a8;
  table {
    width: 100%;
    background-color: #fff;
    font-size: 12px;
    text-align: center;
    border-collapse: collapse;
    border: none;
    
    th, td {
      border: none;
      border-left: 1px solid #f1f1f1;
      border-top: 1px solid #f1f1f1;
      padding: 6px;
    }
    th {
      border-bottom: 1px solid #f1f1f1;
    }
    td:first-child,th:first-child{
      border-left:none;
    }
    tr:first-child td,tr:first-child th{
      border-top:none;
    }
    .priceText {
      color: #227CBE;
      font-weight: bold;
    }
  }
`;

const Price = () => {
  const fvRef = useRef<HTMLDivElement | null>(null);
  const subTitle01Ref = useRef<HTMLDivElement | null>(null);
  const subTitle02Ref = useRef<HTMLDivElement | null>(null);
  const subTitle03Ref = useRef<HTMLDivElement | null>(null);
  const subTextRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const img01Ref = useRef<HTMLDivElement | null>(null);
  const img02Ref = useRef<HTMLDivElement | null>(null);
  const img03Ref = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isSubTitle01RefScrolled, setIsSubTitle01RefScrolled] = useState(false);
  const [isSubTitle02RefScrolled, setIsSubTitle02RefScrolled] = useState(false);
  const [isSubTitle03RefScrolled, setIsSubTitle03RefScrolled] = useState(false);
  const [isSubTextRefScrolled, setIsSubTextRefScrolled] = useState(false);
  const [isTextRefScrolled, setIsTextRefScrolled] = useState(false);
  const [isImg01RefScrolled, setIsImg01RefScrolled] = useState(false);
  const [isImg02RefScrolled, setIsImg02RefScrolled] = useState(false);
  const [isImg03RefScrolled, setIsImg03RefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getTextImg03 = (lang: string): string => {
    let img = ASSETS_IMG03_JA;
    if (lang === LANGUAGE.en) img = ASSETS_IMG03_EN;
    if (lang === LANGUAGE.fr) img = ASSETS_IMG03_FR;
    if (lang === LANGUAGE.de) img = ASSETS_IMG03_DE;
    if (lang === LANGUAGE.it) img = ASSETS_IMG03_IT;
    if (lang === LANGUAGE.ko) img = ASSETS_IMG03_KO;
    if (lang === LANGUAGE.ru) img = ASSETS_IMG03_RU;
    if (lang === LANGUAGE.es) img = ASSETS_IMG03_ES;
    if (lang === LANGUAGE.pt) img = ASSETS_IMG03_PO;
    if (lang === LANGUAGE.zhCN) img = ASSETS_IMG03_CH_CN;
    if (lang === LANGUAGE.zhTW) img = ASSETS_IMG03_CH_TW;
    return img;
  };

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(subTitle01Ref.current)().then(() => setIsSubTitle01RefScrolled(true));
    onScrollPromise(subTitle02Ref.current)().then(() => setIsSubTitle02RefScrolled(true));
    onScrollPromise(subTitle03Ref.current)().then(() => setIsSubTitle03RefScrolled(true));
    onScrollPromise(subTextRef.current)().then(() => setIsSubTextRefScrolled(true));
    onScrollPromise(textRef.current)().then(() => setIsTextRefScrolled(true));
    onScrollPromise(img01Ref.current)().then(() => setIsImg01RefScrolled(true));
    onScrollPromise(img02Ref.current)().then(() => setIsImg02RefScrolled(true));
    onScrollPromise(img03Ref.current)().then(() => setIsImg03RefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(img01Ref.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('price.title')}
      </Title>
      <DetailWrapper>
        <DetailBox>
          <Subtitle ref={subTitle01Ref} data-visible={isSubTitle01RefScrolled}>
            【{t('price.subtitle01')}】
          </Subtitle>
          <TableWrapper data-visible={isImg01RefScrolled}>
            <table>
              <thead>
                <tr>
                  <th>{t('price.tableHeading01')}</th>
                  <th>{t('price.tableHeading02')}</th>
                  <th>{t('price.tableHeading03')}</th>
                  <th>{t('price.tableHeading04')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('price.tableData01')}</td>
                  <td className="priceText">¥1,000</td>
                  <td>5</td>
                  <td>1,000pt</td>
                </tr>
                <tr>
                  <td>{t('price.tableData02')}</td>
                  <td className="priceText">¥5,000</td>
                  <td>20</td>
                  <td>5,000pt</td>
                </tr>
                <tr>
                  <td>{t('price.tableData03')}</td>
                  <td className="priceText">¥20,000</td>
                  <td>80</td>
                  <td>20,000pt</td>
                </tr>
                <tr>
                  <td>{t('price.tableData04')}</td>
                  <td className="priceText">¥50,000</td>
                  <td>200</td>
                  <td>50,000pt</td>
                </tr>
                <tr>
                  <td>{t('price.tableData05')}</td>
                  <td className="priceText">¥100,000</td>
                  <td>400</td>
                  <td>100,000pt</td>
                </tr>
                <tr>
                  <td>{t('price.tableData06')}</td>
                  <td className="priceText">ask</td>
                  <td>ask</td>
                  <td>ask</td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>
        </DetailBox>
        <DetailBox>
          <Subtitle ref={subTitle02Ref} data-visible={isSubTitle02RefScrolled}>
            {t('price.subtitle02')}
          </Subtitle>
          <TableWrapper data-visible={isImg02RefScrolled}>
            <table>
              <thead>
                <tr>
                  <th>{t('price.tableHeading05')}</th>
                  <th>{t('price.tableHeading06')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1,000pt</td>
                  <td>18{t('price.tableData07')}</td>
                </tr>
                <tr>
                  <td>50,000pt</td>
                  <td>900{t('price.tableData07')}</td>
                </tr>
                <tr>
                  <td>100,000pt</td>
                  <td>1800{t('price.tableData07')}</td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>
          <Text dangerouslySetInnerHTML={renderHTML(t('price.text01'))} />
        </DetailBox>
        <DetailBox>
          <Subtitle ref={subTitle03Ref} data-visible={isSubTitle03RefScrolled}>
            {t('price.subtitle03')}
          </Subtitle>
          <SubText ref={subTextRef} data-visible={isSubTextRefScrolled}>
            {t('price.text02')}
          </SubText>
          <ImageWrapper
            ref={img03Ref}
            data-visible={isImg03RefScrolled}
          >
            <Img src={getTextImg03(languageStoreValue)} />
          </ImageWrapper>
          <Text ref={textRef} data-visible={isTextRefScrolled}>
            {t('price.text03')}
          </Text>
        </DetailBox>
        <LinkWrapper data-visible={isFvRefScrolled}>
          <Link
            href={
              languageStoreValue === 'ja'
                ? 'https://docs.google.com/forms/d/1FfyHPuo1VzzgqIS-DvX_17DXf5W_e4JvtJSI1ZOFdGg/edit?usp=sharing'
                : 'https://bit.ly/3hwF1D9'
            }
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('footer.contact')}</span>
          </Link>
        </LinkWrapper>
      </DetailWrapper>
    </Wrapper>
  );
};

export default Price;
